<template>
    <div v-if="visible" class="modal-overlay">
        <div class="modal-content">
            <p class="modal-message">{{ message }}</p>
            <button class="modal-button" @click="handleClose">OK</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            required: true,
        },
        onClose: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            visible: true,
        };
    },
    methods: {
        handleClose() {
            this.visible = false;
            if (typeof this.onClose === 'function') {
                this.onClose();
            }
        },
    },
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    text-align: center;
    width: 400px;
    max-width: 90%;
    animation: scaleUp 0.3s ease-in-out;
}

.modal-message {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
    line-height: 1.5;
}

.modal-button {
    background-color: #007bff;
    color: white;
    font-size: 16px;
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-button:hover {
    background-color: #0056b3;
}

@keyframes scaleUp {
    from {
        transform: scale(0.8);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
